define("frontend/components/invoice-receipt/appointments-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    classNames: ['appointments-list'],
    isPetParent: false,
    appointmentComponent: computed('isPetParent', function () {
      var isPetParent = this.get('isPetParent');

      if (isPetParent) {
        return 'invoice-receipt/client-appointment-group';
      } else {
        return 'invoice-receipt/appointment-group';
      }
    }),
    groupedAppointmentsByOfferedService: computed('groupedAppointments.@each.offered_service', function () {
      var apptsByOfferedService = {};
      this.get('groupedAppointments').forEach(function (appointment) {
        var offeredService = appointment.get('offered_service.id');
        var petCount = appointment.get('appointments.length');
        var key = "".concat(offeredService, "-").concat(petCount);

        if (apptsByOfferedService[key] == null) {
          apptsByOfferedService[key] = {
            offeredServiceId: offeredService,
            groupedAppointments: []
          };
        }

        apptsByOfferedService[key].groupedAppointments.push(appointment);
      }); // Turn the dictionary into an array of dictionaries so we can sort it
      // and still access each element by dot notation

      var entries = Object.entries(apptsByOfferedService).sort(function (x, y) {
        return x[0].localeCompare(y[0]);
      });
      entries = entries.map(function (entry) {
        return {
          key: entry[0],
          info: entry[1]
        };
      });
      return entries;
    })
  });

  _exports.default = _default;
});